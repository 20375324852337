#sliderPictureImage{
    height: 550px;
    opacity: 100%;
    width: 100%;
    object-fit: cover;
}

#sliderPictureImageFront{
    top: 0px;
    left: 0px;
    position:absolute;
    height: 550px;
    z-index: 1;
    width: 100%;
    object-fit: cover;
}

#sliderPictureSimpleImage{
    height: 350px;
    opacity: 42%;
    width: 100%;
    object-fit: cover;
}

#mainSliderPicture{
    width: 100%;
    height: auto;
    background-color: #141413;
}

#sliderPictureWrapper{
    z-index: 10;
    display: flex;
    margin-left: 23%;
    margin-right: 23%;
    position: absolute;
    margin-top: -400px;
    width: auto;
    height: 250px;
}

@media (max-width:1200px) {

    #sliderPictureWrapper{
        gap: 10vw;
    }
}

@media (max-width:780px) {

    #sliderPictureWrapper{
        margin-top: -450px !important;
    }

    #sliderPictureImage, #sliderPictureImageFront{
        height: 550px !important;
    }

}



@media (max-width:760px) {

    #sliderPictureWrapper{
        margin-top: -400px;
    }

    #sliderPictureImage, #sliderPictureImageFront{
        height: 500px;
    }

    #sliderPictureWrapper{
        margin-left: 4% !important;
        margin-right: 4% !important;
        gap: 12%;
        height: 250px;
    }

    #sliderPictureSociales{
        display: none !important;
    }
}


@media (max-width:440px) {

    #sliderPictureWrapper{
        margin-top: -450px;
    }

    #sliderPictureImage, #sliderPictureImageFront{
        height: 550px;
    }



}

@media (max-width:355px) {

    #sliderPictureWrapper{
        margin-top: -450px;
    }

    #sliderPictureImage, #sliderPictureImageFront{
        height: 550px !important;
    }

}

@media (max-width:320px) {

    #sliderPictureWrapper{
        margin-top: -450px;
    }

    #sliderPictureImage, #sliderPictureImageFront{
        height: 550px !important;
    }

}


@media (max-width:285px) {

    #sliderPictureWrapper{
        margin-top: -450px;
    }

    #sliderPictureImage, #sliderPictureImageFront{
        height: 550px !important;
    }

}

@media (max-width:255px) {

    #sliderPictureWrapper{
        margin-top: -550px;
    }

    #sliderPictureImage, #sliderPictureImageFront{
        height: 650px !important;
    }

}

@media (max-width:230px) {

    #sliderPictureWrapper{
        margin-top: -600px;
    }

    #sliderPictureImage, #sliderPictureImageFront{
        height: 700px !important;
    }

}


#sliderPictureSociales{
    margin-top: 50px;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

}

.buttonSociales{
    display: flex;
    justify-content: right;
    align-items: center;
}

.buttonSociales2{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #e2b52d;
}


#header1{
    font-size: 3.5em;
    color: white;
    text-transform: uppercase;
    font-family: 'Open Sans';
    margin-bottom: -10px;
}

#header2{
    font-size: 1.5em;
    color: #e2b52d;
    text-transform: uppercase;
    font-family: 'Open Sans';
}

#p1{
    font-size: 1em;
    color: white;
    font-family: 'Open Sans';
    text-justify: auto;
}

.sliderIcono{
    color: white;
    width: 20px;
    height: 20px;
}

#sliderPictureSimpleText {
    display: flex;
    font-family: 'Open Sans';
    font-size: 3.5em;
    color: white;
    justify-content: center;
    align-items: center;

}

#sliderPictureSimpleWrapper{
    position: absolute;
    display: flex;
    flex-direction: row;
    margin-top: -350px;
    margin-left: 23%;
    margin-right: 23%;
    height: 350px;
    justify-content: center;
    z-index: 0;
    width: 54%;
}

#sliderPictureSimpleText{
    width: 100%;
}

.smoothTransition {
    transition: opacity 2s ease-out;
    opacity: 0%;
}

