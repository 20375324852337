.newsArticleMain{
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    padding-bottom: 40px;
}

.blogNewsArticleImage{
    height: auto;
    width: auto;
    object-fit: cover;
}

.blogNewsArticleImageWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.blogNewsArticleH1{
    font-size: 3.5em;
    color: white;
    text-transform: uppercase;
    font-family: 'Open Sans';
    margin-bottom: -10px;
}

.blogNewsArticleH2{
    font-size: 1.5em;
    color: #e2b52d;
    text-transform: uppercase;
    font-family: 'Open Sans';
}

.blogNewsArticleP1{
    font-size: 1em;
    color: white;
    font-family: 'Open Sans';
    text-justify: auto;
}
