.newsSmallArticleH1{
    color: white;
    font-size: 1.5em;
    text-transform: uppercase;
    font-family: 'Open Sans';
}

.newsSmallArticleH2{
    color: #e2b52d;
    font-size: 1em;
    font-family: 'Open Sans';
}

.newsSmallArticleP1{
    font-size: 1em;
    color: white;
    font-family: 'Open Sans';
    text-align: justify;
    text-justify: inter-word;
}

.newsSmallArticleP2{
    font-size: 1em;
    color: white;
    font-family: 'Open Sans';
    text-justify: auto;
    color: #e2b52d;
}

#newsSmallArticleMain{
    width: 32%;
}

@media (max-width:700px) {

    #newsSmallArticleMain{
        width: 100%;
    }

}
