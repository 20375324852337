#infoCV3Main{
    display: flex;
    position: absolute;
    margin-left: 38vw;
    margin-top: 5vh;
    height: 90vh;
    width: 54vw;
    top: 0;
    left: 0;
    overflow: auto;
}

#infoCV3Wrapper{
    height: auto;
    width: 50vw;
    margin-right: 4vw;
    margin-left: 2vw;
    margin-top: 3vh;
    margin-bottom: 3vh;
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
}

@media(max-width: 750px) {

    #infoCV3Main{
        margin-left: 40vw !important;
        margin-top: 0vh !important;
        height: 100vh !important;
        width: 55vw !important;
    }

    #infoCV3Wrapper{
        margin-right: 0vw !important;
    }

    #infoCV3InnerWrapper{
        gap: 2vh !important;
    }

    .infoCV3WorksRow{
        flex-direction: column !important;
    }

    .infoCV3WorksRowInside{
        width: 100% !important;
    }

}

#infoCV3InnerWrapper{
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-between;
    height: 100%;
}


#infoCV3WorkExp{
    font-family: 'Open Sans';
    color: white;
    font-size: 8vmin;
}


.infoCV3WorksRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 6%;
}

.infoCV3WorksRowInside{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1.3vh;
}

.infoCV3PointTitle{
    font-family: 'Open Sans';
    font-size: 3vmin;
    color: #e2b52d;
    text-align: start;
    width: 100%;
    text-transform: uppercase;

}

.infoCV3WorksText{
    font-family: 'Open Sans';
    font-size: 2.3vmin;
    color: white;
    text-align: justify;
    text-justify: distribute;
    width: 100%;
    margin-top: -2vh;
}

.infoCV3Separator{
    height: 1px;
    background-color: white;
}
