#headerMain{
    height: 150px;
    width: 100%;
    background-color: #141413;
    margin-bottom: -150px;
}

#wrapperHeader{
    height: 100%;
    width: auto;
    display: flex;
    flex-direction: row;
    margin-left: 23%;
    margin-right: 23%;
    z-index: 20;

}

@media(max-width: 760px){

    #wrapperHeader{
        margin-left: 4% !important;
        margin-right: 4% !important;
    }

}

#buttonContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    height: 100%;
    z-index: 100;
    width: 65%;
    gap: 10%;
}

#logo{
    height: auto;
    width: 100px;
}

#logo2, #logo3{
    height: auto;
    width: 50px;
}

.logoContainer{
    display: flex;
    align-items: center;
    width: 20%;
    z-index: 70;
}

#midContainer{
    width: 15%;

}

#smallMenu{
    display: flex;
    justify-content: right;
}

@media (min-width:1250px)  {

    #smallMenu{

        display: none;

    }

    .openClose{

        display: none !important;

    }


}

.opened{

    display: inline;

}

.closed{

    display: none;

}


@media (max-width:1250px)  {

    #buttonContainer{

        display: none;

    }

    #wrapperHeader{

        justify-content: space-between;

    }

}

#smallMenuOpened{

    position: fixed;
    z-index: 80;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #141413;

}

#closeTheSmallMenu{
    position: absolute;
    margin-left: 12%;
    margin-top: 12%;
}

#closeTheSmallMenu:hover{
    cursor: pointer;
}

.logoSmallMenu:hover {

    cursor: pointer;
}


#smallMenuWrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 12%;
    margin-top: 12%;
    gap: 20px;

}
