.buttonHeader{
    display: flex;
    font-family: 'Open Sans';
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: white;
    justify-content: center;
    align-items: center;
}

.buttonHeaderSelected{
    display: flex;
    font-family: 'Open Sans';
    font-size: 17px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #e2b52d;
    justify-content: center;
    align-items: center;
}

.link{
    text-decoration: none;
    color: inherit;
}


