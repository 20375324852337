#infoCV2Main{
    display: flex;
    position: absolute;
    margin-left: 38vw;
    margin-top: 5vh;
    height: 90vh;
    width: 54vw;
    top: 0;
    left: 0;

    overflow: auto;
}

@media(max-width: 750px) {

    #infoCV2Main{
        margin-left: 40vw !important;
        margin-top: 0vh !important;
        height: 100vh !important;
        width: 55vw !important;
    }

    #infoCV2Wrapper{
        margin-right: 4vw !important;
    }


    .infoCV2SkillsRow{
        flex-direction: column !important;
    }

    .infoCV2SkillsSquare{
        width: 100% !important;
    }

    .infoCV2LowerWrapperText{
        font-size: 2.7vw !important;
    }

    .infoCV2SkillBar100{
        width: 30vw !important;
    }

    .infoCV2SkillBar40Back{
        width: 30vw !important;
    }

    .infoCV2PointTitle{
        font-size: 2.9vmin !important;
    }

    .infoCV2Text{
        font-size: 2.9vmin !important;
    }



}

#infoCV2Wrapper{
    height: auto;
    width: 50vw;
    margin-right: 4vw;
    margin-left: 2vw;
    margin-top: 3vh;
    margin-bottom: 3vh;
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
}

#infoCV2MyEducation{
    font-family: 'Open Sans';
    color: white;
    font-size: 8vmin;
}

.infoCV2PointTitle{
    color: #e2b52d;
    font-family: 'Open Sans';
    font-size: 2.1vmin;
    width: 100%;
}

.infoCV2Text{
    color: white;
    font-family: 'Open Sans';
    font-size: 2.1vmin;
}

.infoCV2Divisor{
    display: flex;
    background-color: white;
    color: #e2b52d00;
    height: 1px;
}

.infoCV2Subtitle{
    font-family: 'Open Sans';
    font-size: 4.4vmin;
    color: white;
    text-align: justify;
    text-justify: kashida;
    padding-bottom: 5vh;
    padding-top: 2vh;
}

.infoCV2SkillsTable{
    display: flex;
    flex-direction: column;
}

.infoCV2SkillsRow{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20%;
}

.infoCV2SkillsSquare{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 40%;
}

#infoCV2LowerWrapper{
    margin-top: 2vh;
}


.infoCV2LowerWrapperText{
    font-family: 'Open Sans';
    font-size: 1.5vw;
    color: white;
    text-align: justify;
    text-justify: kashida;
    width: 15%;
}

.infoCV2SkillBar100{
    background-color: #ededfe;
    width: 8vw;
    height: 1vh;
    border: solid;
    border-color: #404039;
    border-width: 2px;
}

.infoCV2SkillBar40Back{
    background-color: #131312;
    width: 8vw;
    height: 1vh;
    border: solid;
    border-color: #404039;
    border-width: 2px;
}

.infoCV2SkillBar40{
    margin-top: -2px;
    margin-left: -2px;
    background-color: #ededfe;
    width: 40%;
    height: 1vh;
    border: solid;
    border-color: #404039;
    border-width: 2px;
}
