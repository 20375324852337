.coolButtonWhite{
    margin-top: 30px;
    display: flex;
    background-color: white;
    text-transform: uppercase;
    color: #141413;
    font-family: 'Open Sans';
    height: 50px;
    width: fit-content;
    padding-left: 30px;
    padding-right: 30px;
    justify-content: center;
    align-items: center;
    letter-spacing: 4px;
    border-radius: 50px;
    font-size: 1em;
    width: 150px;
    border: none;
}

.coolButtonWhite:hover{
    cursor: pointer;
}

.coolButtonYellow{
    margin-top: 30px;
    display: flex;
    background-color: white;
    text-transform: uppercase;
    color: #141413;
    font-family: 'Open Sans';
    height: 50px;
    width: fit-content;
    padding-left: 30px;
    padding-right: 30px;
    justify-content: center;
    align-items: center;
    letter-spacing: 4px;
    border-radius: 50px;
    font-size: 1em;
    width: 180px;
}

