#photoBigPhotoWrapper{
    position: absolute;
    margin-left: 13vw;
    width: 25vw;
    margin-top: 2vh;
    height: 96vh;
    top: 0;
    left: 0;
}

@media(max-width: 750px){

    #photoBigPhotoWrapper{

        margin-left: 10vw !important;
        margin-top: 0vh !important;
        height: 99.7vh !important;
        width: 30vw !important;
    }

}

#photoBigPhotoImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
}

#photoBigPersonalInfoWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 13vw;
    margin-top: 81vh;
    width: 25vw;
    z-index: 100;
    height: 16vmin;
}

#photoBigPersonalInfoSocialsWrapper{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 1vw;
}

.photoBigPersonalInfoSocialIcon{
    color: white;
    font-size: 2vmin;
}

.photoBigPersonalInfoSocialIcon:hover{
    color: #e2b52d;
}

.photoBigPersonalInfoNameP{
    color: white;
    font-size: 5vmin;
    font-family: 'Open Sans';
    user-select: none;
}

.photoBigPersonalInfoOccupationP{
    color: #e2b52d;
    font-size: 2.4vmin;
    font-family: 'Open Sans';
    user-select: none;
}
