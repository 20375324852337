#footerWrapper{
    margin-left: 23%;
    margin-right: 23%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.footerGoUp{
    margin-left: 23%;
    margin-right: 23%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
}

@media(max-width: 760px){

    .footerGoUp{
        margin-left: 4% !important;
        margin-right: 4% !important;
    }

}

#goUpFooterMain{
    background-color: #141413;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-bottom: 10px;
}
.buttonSociales:hover{
    cursor: pointer;

}
.buttonSociales2:hover .sliderIcono{
    cursor: pointer;
    color: #141413;

}

