#menuOpenedCVMain{
    position: absolute;
    width: 30vw;
    height: 96vh;
    background-color: black;
    opacity: 95%;
    display: flex;
    flex-direction: column;
    z-index: 400;
    display: flex;
    flex-direction: row;
    margin-top: -3vh;
}

@media(max-width: 750px) {

    #menuOpenedCVMain{
        width: 40vw !important;
        height: 100vh !important;
        margin-top: 0vh !important;
    }

    #menuOpenedCVMainCloseMenuWrapper{
        margin-left: 1vw !important;
        margin-top: -2vh !important;
    }

    #menuOpenedCVMainCloseMenuIcon{
        font-size: 6vmax !important;
    }

    #menuOpenedCVOptionsWrapper{
        margin-bottom: 5vh !important;
        margin-top: 5vh !important;
    }

}

#menuOpenedCVMainCloseMenuWrapper{
    margin-top: 1.5vh;
    margin-left: -0.2vw;
    width: 50%;
    height: 100%;
}

#menuOpenedCVMainCloseMenuIcon{
    color: white;
    font-size: 3vmax;
    margin-left: 2vw;
    margin-top: 3.5vh;
}

#menuOpenedCVMainCloseMenuIcon:hover{
    cursor: pointer;
}

#menuOpenedCVOptionsWrapper{
    align-items: end;
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: space-between;
    align-content: space-between;
    margin-bottom: 5%;
    margin-top: 5%;
    margin-right: 5%;
    width: 45%;
}

.menuOpenedCVOptionsLink{
    text-decoration: none;
    color: #e2b52d;
    font-family: 'Open Sans';
    font-size: 1.65vmax;
    letter-spacing: 0.2vmax;
    user-select: none;
}

.menuOpenedCVOptionsLink:hover{
    color: darkgray;
}

.menuOpenedCVOptionsDivisor{
    height: 1px;
    width: 100%;
    background-color: darkgray;
}
