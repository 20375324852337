#newsRecapMain{
    background-color: #141413;
    height: auto;
    padding-top: 1px;
    padding-bottom: 50px;
}
#newsRecapWrapper{
    margin-left: 23%;
    margin-right: 23%;
    display: flex;
    flex-direction: column;
}
#newsRecapLatest{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 8%;
}

@media (max-width:760px) {



    #newsRecapWrapper{
        margin-left: 4% !important;
        margin-right: 4% !important;
    }

}

@media (max-width:700px) {

    #newsRecapLatest{
        flex-direction: column !important;
    }

    #newsRecapWrapper{
        margin-left: 4% !important;
        margin-right: 4% !important;
    }

}

#newsRecapWrapperH1{
    font-size: 3.5em;
    color: white;
    text-transform: uppercase;
    font-family: 'Open Sans';
    margin-bottom: -20px;
}

#newsRecapWrapperH2{
    font-size: 1.5em;
    color: #e2b52d;
    text-transform: uppercase;
    font-family: 'Open Sans';
    margin-bottom: 40px;
}


