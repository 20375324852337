::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #999998;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #303029;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #090908;
}

#mainCV{
    width: 100vw;
    height: 100vh;
    background-color: black;
    position: absolute;
    top: 0;
    left: 0;

}


#wrapperCV{
    display: flex;
    width: 84vw;
    height: 90vh;
    margin-top: 5vh;
    margin-bottom: 5vh;
    margin-left: 8vw;
    margin-right: 8vw;
    background-color: #141413;
}



@media(max-width: 750px){

    #wrapperCV{
        width: 100vw !important;
        height: 100vh !important;
        margin: 0vh 8vw 0vh 0vw !important;
    }

}
