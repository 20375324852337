.slideshowMain{
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #141413;
    align-content: center;
}

.slideshowWrapper{
    display: flex;
    flex-direction: column;
}

.slideshowTextWrapper{
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.each-fade {
    display: flex;
    width: 100%;
}

.each-fade > div {
    width: 100%;
}

.each-fade > div img {
    width: 100%;
    object-fit: cover;
}

.react-slideshow-container+ul.indicators{

}

.react-slideshow-container+ul.indicators .each-slideshow-indicator:before{
    background: white;
}

@media (max-width:850px) {

    .react-slideshow-container+ul.indicators .each-slideshow-indicator:before{

        display: none

    }

}

.slideEmpty{
    background-color: #141413;
    height: 75px;
}

.slideshowText{
    margin-right: 23%;
    margin-left: 23%;
    font-family: 'Open Sans';
    color: #e2b52d;
    font-size: 3.5em;
}

.slideshowExplained{
    margin-right: 23%;
    margin-left: 23%;
    height: auto;
    font-family: 'Open Sans';
    color: white;
    font-size: 1em;
    text-align: justify;
    text-justify: kashida;
}


@media (max-width:760px) {

    .slideshowText, .slideshowExplained, .slideshowCustomLinkWrapper{

        margin-right: 4% !important;
        margin-left: 4% !important;
    }

}

@media (max-width:1300px) {

    .slideshowText{

        font-size: 3.5em;

    }

}



.slideshowCustomLinkMain{
    background-color: #141413;
}

.slideshowCustomLinkWrapper{
    margin-right: 23%;
    margin-left: 23%;
}

.slideshowCustomLinkLink{
    display: inline-block;
    background-color: #141413;
    border-top-color: #e2b52d;
    border-right-color: #e2b52d;
    border-bottom-color: #e2b52d;
    border-left-color: #e2b52d;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    color: #e2b52d;
    font-family: 'Open Sans';
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
    padding: 10px 20px;
    font-size: 1em;
    width: auto;
}

.slideshowCustomLinkLink:hover{
    color: white;
    border-top-color: white;
    border-right-color: white;
    border-bottom-color: white;
    border-left-color: white;
}

.slideshowCustomEmptySpace{
    height: 5vh;
    width: 100%;
}
