#menuLeftCVMain{
    width: 5vw;
    height: 90vh;
    background-color: #141413;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media(max-width: 750px){

    #menuLeftCVMain{
        width: 10vw !important;
        height: 100vh !important;
    }

    .menuLeftCVYellowSquare{
        width: 10vw !important;
        height: 10vw !important;
        padding-bottom: 0 !important;

        font-size: 7vw !important;
    }

    .menuLeftCVYellowSquare:before{
        display:     flex !important;
        padding-top: 0% !important;
    }

    .menuLeftCVYellowSquare2{
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .menuLeftCVYellowSquareIcon{
        font-size: 5vw !important;
    }

    .menuLeftCVSelectIcon{
        font-size: 5vw !important;
    }

}

.menuLeftCVYellowSquare{
    background-color: #e2b52d;
    width: 100%;
    padding-bottom: 100%;
    height: 0;
    color: #141413;
    font-size: 3.4vw;
}

.menuLeftCVYellowSquare:before{
    display:     block;
    padding-top: 100%;
}

.menuLeftCVYellowSquare2{
    height: 100%;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
}

.menuLeftCVSelectIcon{
    color: white;
    font-size: 2.5vw;
}

.menuLeftCVSelectIcon:hover{
    color: #e2b52d !important;
    cursor: pointer;
}

.menuLeftCVYellowSquareIcon{
    color: #141413;
    font-size: 2.5vw;
}

.menuLeftCVYellowSquareIcon:hover{
    color: white;
    cursor: pointer;
}

#menuLeftCVSelectWrapper{
    display: flex;
    justify-content: center;
}

#menuLeftCVSelectWrapper2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 3vh;
}

.closed{
    display: none !important;
}
