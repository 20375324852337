#contactMain{
    background-color: #141413;
    width: 100%;
}

#contactWrapper{
    display: flex;
    margin-left: 23%;
    margin-right: 23%;
    flex-direction: column;
}

#contactFill{
    display: flex;
    width: 100%;
    margin-bottom: 50px;
}

.form{
    width: 100%;
}

.formPiece{
    width: 100%;
}

#nameForm{
    width: 100%;
    font-size: 1em;
    font-family: 'Open Sans';
}

#emailForm{
    width: 100%;
    font-size: 1em;
    font-family: 'Open Sans';
}

#textForm{
    width: 100%;
    font-size: 2em;
    height: 300px;
    font-size: 1em;
    font-family: 'Open Sans';
}

.contactP{
    font-family: 'Open Sans';
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
    font-size: 1em;
    color: #e2b52d;
    margin-bottom: 5px;
    margin-top: 15px;
}

#contactAnyQuestions{
    padding: 20px;
    background-color: #141413;
    border-top-color: #e2b52d;
    border-right-color: #e2b52d;
    border-bottom-color: #e2b52d;
    border-left-color: #e2b52d;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    margin-top: 30px;
}

#contactH2{
    font-family: 'Open Sans';
    text-transform: uppercase;
    letter-spacing: 2px;
    font-weight: 700;
    color: #e2b52d;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1em;
}

#contactP2{
    font-family: 'Open Sans';
    color: #ffffff;
    font-weight: 400;
    margin-bottom: 8px;
    margin-top: 0px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-size: 1em;
}

.buttonInput{
    margin-top: 30px;
    display: flex;
    background-color: white;
    text-transform: uppercase;
    color: #141413;
    font-family: 'Open Sans';
    height: 50px;
    width: fit-content;
    padding-left: 30px;
    padding-right: 30px;
    justify-content: center;
    align-items: center;
    letter-spacing: 4px;
    outline:0;
    border: none;
}

.buttonInput:focus {outline:0;}

@media(max-width: 760px){

    #contactWrapper{
        margin-left: 4% !important;
        margin-right: 4% !important;
    }

}
