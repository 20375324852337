#footerWrapper{
    margin-left: 23%;
    margin-right: 23%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
}

.footerGoUp{
    margin-left: 23%;
    margin-right: 23%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
}

#footerMain{
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 100%;
    height: 135px;
}

#footerSocials{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 5px;
}

#footerText{
    color: white;
    align-items: center;
    justify-content: center;
}

#footerBold{
    color: #e2b52d;
}

@media(max-width: 760px){

    #footerWrapper{
        margin-left: 4% !important;
        margin-right: 4% !important;
    }

}

@media(max-width: 360px){

    #footerWrapper{
        flex-direction: column !important;
        justify-content: center !important;
        gap: 2vh !important;
    }

}
