#blogNewsMain{

    background-color: #141413;
    display: flex;
    flex-direction: column;

}

#blogNewsWrapper{

    display: flex;
    flex-direction: column;
    margin-right: 23%;
    margin-left: 23%;

}

@media(max-width: 760px){

    #blogNewsWrapper{
        margin-left: 4% !important;
        margin-right: 4% !important;
    }

}
