.rendersMain{
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
}

#rendersMain2{
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
}

.rendersWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.rendersH1{
    color: #e2b52d;
    font-family: 'Open Sans';
    font-size: 3em;
}
.rendersH2{
    color: white;
    font-family: 'Open Sans';
    text-align: center;
    font-size: 1.6em;
}

@media(max-width: 750px) {

    .rendersH1{
        font-size: 2.5em !important;
    }

    .rendersH2{
        font-size: 1.3em !important;
    }

}

