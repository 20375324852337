#infoCV1Main{
    display: flex;
    position: absolute;
    margin-left: 38vw;
    margin-top: 5vh;
    height: 90vh;
    width: 54vw;
    top: 0;
    left: 0;

    overflow: auto;
}

@media(max-width: 750px) {

    #infoCV1Main{
        margin-left: 40vw !important;
        margin-top: 0vh !important;
        height: 100vh !important;
        width: 55vw !important;
    }

    #infoCV1Wrapper{
        margin-right: 0vw !important;
        margin-left: 4vw !important;
    }

    .infoCV1SkillsRow{
        flex-direction: column !important;
        gap: 2vw !important;
    }

    .infoCV1SkillsSquare{
        width: 100% !important;
    }

    .infoCV1AgePlaceOccupationText{
        font-size: 3vmin !important;
    }

    .infoCV1AgePlaceOccupationBars{
        font-size: 3vmin !important;
    }

    .infoCV1Summary{
        font-size: 2.8vmin !important;
    }

}

#infoCV1Wrapper{
    height: auto;
    width: 50vw;
    margin-right: 4vw;
    margin-left: 2vw;
    margin-top: 3vh;
    margin-bottom: 3vh;
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
}

#infoCV1AboutMe{
    font-family: 'Open Sans';
    color: white;
    font-size: 8vmin;
}

#infoCV1AgePlaceOccupation{
    display: flex;
    flex-direction: row;
    gap: 1vw;
    justify-content: start;
}

.infoCV1AgePlaceOccupationText{
    font-family: 'Open Sans';
    font-size: 2.2vmin;
    color: #e2b52d;
}

.infoCV1AgePlaceOccupationBars{
    font-family: 'Open Sans';
    font-size: 2.2vmin;
    color: dimgray;
}

.infoCV1Summary{
    font-family: 'Open Sans';
    font-size: 2vmin;
    color: white;
    text-align: justify;
    text-justify: kashida;
}

.infoCV1Divisor{
    display: flex;
    background-color: white;
    color: #e2b52d00;
    height: 1px;
}

.infoCV1Subtitle{
    font-family: 'Open Sans';
    font-size: 5vmin;
    color: white;
    text-align: justify;
    text-justify: kashida;
    margin-bottom: 3vh;
    margin-top: 2vh;
}

.infoCV1SkillsTable{
    display: flex;
    flex-direction: column;
    gap: 2vw;
}

.infoCV1SkillsRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 14%;
}

.infoCV1SkillsSquare{
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 47%;
}

.infoCV1SkillsSquare{
    font-size: 1.5vmax;
    color: #e2b52d;
    margin-bottom: -1vw;
}

.infoCV1SkillsSquareIcon{
    font-size: 3vmin;
    color: #e2b52d;
    margin-bottom: -1vw;
}

.infoCV1SkillsSquareTitle{
    color: lightgrey;
    font-family: 'Open Sans';
    font-size: 2.4vmin;
}

.infoCV1SkillsSquareText{
    color: white;
    font-family: 'Open Sans';
    font-size: 2.4vmin;
    margin-top: -1vw;
    text-align: justify;
    text-justify: kashida;
}
